import React from "react";
import Helmet from "react-helmet";
import ContactBar from "../components/ContactBar";
import BookNow from "../components/BookNow";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Layout from "../components/Layout";

const NetzwerkPage = (props) => (
  <Layout location={props.location}>
    <div id="network" className="page">
      <Helmet>
        <title>Netzwerk - TCM Praxis Paola Nakahara-Hoffmann Oerlikon</title>
        <meta
          name="description"
          content="Mein Netzwerk - TCM Praxis Paola Nakahara-Hoffmann"
        />
        <meta
          property="og:title"
          content="Netzwerk - TCM Praxis Paola Nakahara-Hoffmann"
        />
        {/* <meta property="og:description" content="Behandlungsdauer, Kosten und Krankenkassenanerkennung" /> */}
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Mein Netzwerk</h1>
        <p>
          Karen Fletcher <strong>Dipl. Homöopathin SkHZ </strong>
          <a
            href="http://www.kfletcher.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.kfletcher.ch
          </a>
        </p>
        <p>
          Gabriela Bucher <strong>Dipl. Esalen® Massagetherapeutin </strong>
          <a
            href="http://www.gabriela-bucher.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.gabriela-bucher.ch
          </a>
        </p>
        <p>
          Sarah Waser <strong>Dipl. Gesundheitstherapeutin</strong>{" "}
          <a
            href="http://www.sarahwaser.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.sarahwaser.ch
          </a>
        </p>
        <p>
          Katrin Rechsteiner <strong>Hebamme</strong>{" "}
          <a
            href="https://www.stadt-hebammen.ch/team"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.stadt-hebammen.ch/team
          </a>
        </p>
        <p>
          Gyn Praxis Stockerstrasse <strong>Frauenpraxis</strong>{" "}
          <a
            href="https://www.gynstockerstrasse.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.gynstockerstrasse.ch/
          </a>
        </p>
      </div>
      <BookNow />
      <Contact />
      <Footer />
    </div>
  </Layout>
);

export default NetzwerkPage;
